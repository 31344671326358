
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class HomeView extends Vue {
  domain?: string = "https://api.lotto24hr.com";
  secret_key?: string;
  username?: string;
  url?: string;

  data() {
    return {
      domain: "https://api.lotto24hr.com",
      secret_key: "",
      username: "",
      url: "",
    };
  }

  launch() {
    var config = {
      method: "get",
      url: `${this.domain}/api/memberplaygame/launching?secret_key=${this.secret_key}&username=${this.username}&Option`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        // console.log(response.data);
        const data = response.data;
        this.url = data?.data?.url;
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.request);
        // console.log(error.response);
        alert(error.message)
      });
  }
}
